import s from '../styles/news.module.css';
import OJLayout from '../components/layout';
import Link from 'next/link';
import Head from 'next/head';
import * as envConstants from '../components/constants';

// Component de structure pour les "rappels"
const OJRappelComposant = ({itemText, itemLienText="Lien", itemLienAdr=null, itemLienTargetblank=false}) => {
  return (
    <div className="flex flex-row mx-2 mb-1">
      <svg className="w-4 h-5 flex-none mr-1 mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 7 7">
        <path d="M 0 0 L 4 0 L 6 3 L 4 6 L 0 6 L 2 3 L 0 0 L 1 0" stroke="#10aab0" strokeWidth="1" fill="none"/>
      </svg>
      <p>{itemText}</p>
      {itemLienAdr == null ? '' :
      <Link href={itemLienAdr}>
          <a className={s.lienRappel} target={itemLienTargetblank ? "_blank" : ''} rel={itemLienTargetblank ? "noreferrer" : ''}> ({itemLienText})</a>
      </Link> }
    </div>             
  );
};

// This gets called on every request
export async function getServerSideProps() {
  // Appel l'API
  try{
    // const resp = await fetch(envConstants.WEBSITE_URL+'/api/infoNewsService', {
    //   method: 'GET',
    //   headers: { 'Content-Type': 'application/ld+json' }
    // });  
    const listInfoNews = ""; //await resp.json();

    // Retourne les data à la page via props
    return { props: { listInfoNews } }
  }catch(err){
    console.log(err);
    return { props: { listInfoNews:[] } };
  }
}

export default function News({listInfoNews}) {
  const pageTitle = envConstants.SITE_NAME_OJ + " - News";
  // Défini s'il y a des infoNews de récupérées.
  const hasSomeInfoNews = listInfoNews.length > 0;

  return (
    <OJLayout>
        <Head><title>{pageTitle}</title></Head>

      {/* Zone d'affichage des dates clés / breaking news / infos de dernières min / Rappels */}
      <div className={s.divRappels}>
        <div className={s.titreRappels}>Rappels : </div>
        {/* Exemple : <OJRappelComposant itemText="Stage : les 16 - 17 Avril à Nantes - Nationnal EAJJ" itemLienText="Evènement" itemLienAdr="https://fb.me/e/1xnK4KdLX" itemLienTargetblank={true} /> */}
        { !hasSomeInfoNews || listInfoNews==null || listInfoNews[0]==null ? <div className="m-4">Pas d'infos ni rappels pour le moment...</div> :
          listInfoNews.map( (infoNews, index) => (
            <OJRappelComposant key={index} itemText={infoNews?.description||null} itemLienText={infoNews?.lienText||null} itemLienAdr={infoNews?.lienURL||null} itemLienTargetblank={infoNews?.lienIsTargetBlank||true} />
          ))
        }
      </div>

      {/* Séparateur de section : News */}
      <div className={s.divSeparateurNews}>
        <div className={s.verticalLineGreenSmall} />
        <span className={s.titreSection}>News :</span>
        <div className={s.verticalLineGreenLarge} />
      </div>
      
      {/* News */}
        
      <div className={s.divNews}>
        {/* Page FACEBOOK */}        
        <div className="flex flex-col w-fit">
          <p className="m-auto">en direct de la page officielle Facebook :</p>
          {/* For Mobile */}
          <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fopenjujitsu%2F&tabs=timeline&small_header=true&width=345&height=600&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1146882228980106" 
                scrolling="no" frameBorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                width="345" height="600" className="sm:hidden flex">
          </iframe>
          {/* For Siteweb */}
          <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fopenjujitsu%2F&tabs=timeline&small_header=true&width=500&height=600&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1146882228980106" 
                scrolling="no" frameBorder="1" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                width="500" height="600" className="hidden sm:flex">
          </iframe>
        </div>
        <div className="mx-auto mt-10">          
          <p className="font-bold mt-10">Reprise des cours pour la saison 2024-2025 : le Mardi 2 Septembre ! :)</p>          
        </div>     
      </div>

    </OJLayout>
  )
}
